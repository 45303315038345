import React from "react";
import { Link, navigateTo } from "gatsby";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { loginPatient } from "../../api/actions/login";
import { stroreUser } from "../../state/app.js";
import { useDispatch } from "react-redux";
export default function LoginEmail({ setshowemail }) {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const dispatch = useDispatch();
  const SignupSchema = Yup.object().shape({
    login_identifier: Yup.string()
      .min(3, "Login Identifier must be 3 characters at minimum")
      .required("Login Identifier is required"),

    password: Yup.string()
      .min(7, "Password must be 7 characters at minimum")
      .required("Password is required"),
  });

  const onSubmitForm = async (formData) => {
    setError("");
    setIsSubmitting(true);

    const result = await loginPatient(formData);

    if (result && result.success) {
      navigateTo("/");
      return;
    }
    setIsSubmitting(false);
    setError("Invalid Email/Password");
  };
  const [error, setError] = React.useState("");
  return (
    <div className="signup loginemail form">
      <h1>Login</h1>
      <h3 className="switch-way">
        {/* Login with <span onClick={() => setshowemail(false)}>Phone number</span> */}
      </h3>
      <Formik
        initialValues={{
          login_identifier: "",
          password: "",
        }}
        validationSchema={SignupSchema}
        onSubmit={(data) => {
          onSubmitForm(data);
        }}
      >
        {({ touched, errors }) => (
          <Form>
            <div className="formgroup form-group">
              <label htmlFor="Email">Email or Username</label>
              <Field
                type="text"
                name="login_identifier"
                placeholder="Enter Email or Username"
                className={`form-control ${
                  touched.login_identifier && errors.login_identifier
                    ? "is-invalid"
                    : ""
                }`}
              />
              <ErrorMessage
                component="div"
                name="login_identifier"
                className="invalid-feedback"
              />
              <label>Password</label>
              <Field
                type="password"
                name="password"
                placeholder="Enter Password"
                className={`form-control ${
                  touched.password && errors.password ? "is-invalid" : ""
                }`}
              />
              <ErrorMessage
                component="div"
                name="password"
                className="invalid-feedback"
              />
              <Link className="forgetpassword" to="/login/recover">
                Forgot your password ?
              </Link>
              {error && <div className="error-custom-login">{error}</div>}
              <button type="submit" className="next" disabled={isSubmitting}>
                {isSubmitting ? "Submitting...  " : "Next"}
              </button>

              <h3>
                New user?
                <Link to="/signup"> Register</Link>
              </h3>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
