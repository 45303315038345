import React, { useState } from "react";
import LoginEmail from "./byEmail.js";
import LoginPhone from "./byPhone";
import Authheader from "../layout/header/autheader";
import "./assets/css/styles.scss";

export default function Signup() {
  const [showemail, setshowemail] = useState(true);
  return (
    <>
      <Authheader />
      <div className="login-form">
        {showemail ? (
          <LoginEmail setshowemail={setshowemail} />
        ) : (
          <LoginPhone setshowemail={setshowemail} />
        )}
      </div>
    </>
  );
}
