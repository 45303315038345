import API from "../utils/axios";
import { saveTokentoLocalStorage, saveUserInfo } from "../utils/local-storage";

export const loginPatient = async (data) => {
  try {
    const response = await API.post("/login", data, {
      "Content-Type": "application/json",
    });

    if (response && response.data && response.data.auth_token) {
      saveTokentoLocalStorage(response.data.auth_token);
      saveUserInfo(JSON.stringify(response.data));
    }
    return { success: 1, information: response };
  } catch (err) {
    return { success: 0 };
  }
};
